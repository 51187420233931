import React from 'react'

const Hero = ({ children }) => {
    return (
        <>
          {children}
        </>
    );
}

export default Hero;